





























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import dateFormat from 'dateformat';
import { dateFormats } from '@/repository/kontent/utilities/languageCodes';
import { reactive } from '@vue/composition-api';
import { LeadershipStore } from '@/repository/kontent/stores/leadershipStore';
import I18n from '@/i18n/index';
import LocalNav from './LocalNav.vue';

@Component({
  components: {
    LocalNav,
  },
})
export default class Leasership extends Vue {
  private leadershipData = reactive(new LeadershipStore());
  private i18n = I18n;
  public created(): void {
    this.leadershipData.restore();
    dateFormat.i18n = dateFormats[this.$i18n.locale] || dateFormats['ja'];
  }
  @Watch('i18n.language')
  onTextChanged(): void {
    this.leadershipData = reactive(new LeadershipStore());
    this.leadershipData.restore();
  }

  private historyDateFormat(value: string): string {
    if (this.$i18n.locale == 'ja') {
      return dateFormat(value, 'yyyy年m月');
    }
    return dateFormat(value, 'mmm, yyyy');
  }

  private getBrSeparatedText(text: string) {
    return text?.split('\n');
  }
  private get RepresentativeDirector() {
    return this.leadershipData.getRepresentativeDirector();
  }
  private get Director() {
    return this.leadershipData.getDirector();
  }
  private get OutsideDirectors() {
    return this.leadershipData.getOutsideDirectors();
  }
  private get targetName() {
    return this.$route.name;
  }
}
