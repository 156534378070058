import Vue from 'vue';
import Store from './base/store';
import { Leadership } from "../models/leadership";
import { Leader } from "../models/leader";
import { Simplehistory } from "../models/_simplehistory"

type LeaderData = {
  name: string,
  position: string,
  pictureUrl: string,
  license: string,
  history: Simplehistory[],
  specialty: string,
  message: string,
}

class LeadershipStore extends Store<Leadership>{

  constructor(){
    super('leadership')
    this.setDepth(2)
  }
  public getRepresentativeDirector() {
    const RepresentativeDirectorData = this
      .list[0]?.leaders.value
        .find((x) => x.position_type?.value[0].codename == "representative_director" )
    return this.makeLeaderData(RepresentativeDirectorData as Leader)
  }

  public getDirector(){
    const DirectorData = this
      .list[0]?.leaders.value
        .find((x) => x.position_type?.value[0].codename == "director" )
    return this.makeLeaderData(DirectorData as Leader)
  }
  public getOutsideDirectors(){
    const OutsideDirectorData = this
      .list[0]?.leaders.value
        .filter((x) => x.position_type?.value[0].codename == "outside_director" )
    return OutsideDirectorData ? 
    OutsideDirectorData.map((x) => this.makeLeaderData(x as Leader)) : []
  }

  private makeLeaderData(content:Leader | undefined):LeaderData{
    if (!content) return {
      name: "",
      position: "",
      pictureUrl: "",
      license: "",
      history: [],
      specialty: "",
      message: "",
    }
    return {
      name: content.name?.value,
      position: content.position?.value,
      pictureUrl: content.picture?.value[0]?.url,
      license: content.license?.value,
      history: content.history?.value as unknown as Simplehistory[],
      specialty: content.specialty?.value,
      message: content.message?.value,
    }
  }
}

export { LeadershipStore, Leadership, LeaderData };
